<template>
  <b-row>
    <b-col cols="12" md="6">
      <eposta-tanimla />
    </b-col>
  </b-row>
</template>

<script>
import EpostaTanimla from './component/tanimla.vue';
import EpostaInfo from './component/info.vue';
export default {
  components: {
    EpostaTanimla,
    EpostaInfo,
  },
};
</script>

<style lang="scss" scoped></style>
