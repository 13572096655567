<template>
  <b-card-normal title="E posta Bilgileri" :showLoading="show">
    <template v-slot:body>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider name="Kullanıcı Adı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Kullanıcı Adı">
                  <b-form-input
                    class="rounded-0"
                    ref="kullanici_adi"
                    v-model="form.kullanici_adi"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider name="Şifre" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Şifre">
                  <b-form-input
                    class="rounded-0"
                    ref="sifre"
                    type="password"
                    v-model="form.sifre"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider name="Port" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Port">
                  <b-form-input
                    class="rounded-0"
                    ref="port"
                    v-model="form.port"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider name="Host" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Host">
                  <b-form-input
                    class="rounded-0"
                    ref="host"
                    v-model="form.host"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button squared block type="submit" :disabled="!firmaKNo" variant="primary">
                <feather-icon icon="CheckSquareIcon" class="mr-50" />
                <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    BCardNormal,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.show = ref(false);
    expo.form = ref({
      kullanici_adi: null,
      sifre: null,
      port: null,
      host: null,
    });

    const handlerConfig = async () => {
      expo.show.value = true;
      await store.dispatch('epostaAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      expo.show.value = false;
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('epostaAyarlariEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
