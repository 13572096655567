<template>
  <b-card-normal title="Sms Kredi Bilgileri">
    <template v-slot:body>
      <div>sdds</div>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
export default {
  components: {
    BCardNormal,
  },
};
</script>

<style lang="scss" scoped></style>
